import Vue from 'vue'
import './styles/quasar.sass'
import lang from 'quasar/lang/pt-br.js'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import iconSet from 'quasar/icon-set/mdi-v4.js'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import VueClipboard from 'vue-clipboard2'
import VueEasyLightbox from 'vue-easy-lightbox'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueEasyLightbox)

Vue.use(Quasar, {
  lang,
  iconSet: iconSet,
  animations: 'all',
  plugins: {
    Notify,
    Dialog
  }
})
