import router from '@/router'
import { ROUTE_FEEDS } from '@/modules/feed/constants'

import {
  ROUTE_AUTH_SIGN_IN,
  ROUTE_AUTH_SIGN_UP,
  ROUTE_AUTH_SIGN_MFA,
  ROUTE_AUTH_PASSWORD_FORGOT,
  ROUTE_AUTH_PASSWORD_DEFINE,
  ROUTE_AUTH_PASSWORD_CHANGE
} from '@/modules/auth/constants'

const authRoutes = [
  ROUTE_AUTH_SIGN_IN,
  ROUTE_AUTH_SIGN_UP,
  ROUTE_AUTH_SIGN_MFA,
  ROUTE_AUTH_PASSWORD_DEFINE,
  ROUTE_AUTH_PASSWORD_FORGOT,
  ROUTE_AUTH_PASSWORD_CHANGE
]

export default () => (store) => {
  router.beforeEach((route, _, next) => {
    const hasCognitoUser = store.getters.hasCognitoUser
    const isAuthenticated = store.getters.isAuthenticated
    const redirectAuthRoute = checkRedirectAuthRoute(route, isAuthenticated, hasCognitoUser)

    if (redirectAuthRoute) {
      store.dispatch('ROUTE_CHANGED', redirectAuthRoute)
      return next({ name: redirectAuthRoute })
    }

    store.dispatch('ROUTE_CHANGED', route.name)
    next()
  })
}

const checkRedirectAuthRoute = (route, isAuthenticated, hasCognitoUser) => {
  const isAuthRoute = authRoutes.includes(route.name)

  const shouldRedirectToMain = isAuthenticated && isAuthRoute
  if (shouldRedirectToMain) return ROUTE_FEEDS

  if (isAuthenticated) return
  if (!isAuthRoute) return ROUTE_AUTH_SIGN_IN

  const denyRouteMFA = route.name === ROUTE_AUTH_SIGN_MFA && !hasCognitoUser
  if (denyRouteMFA) return ROUTE_AUTH_SIGN_IN

  const denyRouteDefinePassword = route.name === ROUTE_AUTH_PASSWORD_DEFINE && !hasCognitoUser
  if (denyRouteDefinePassword) return ROUTE_AUTH_SIGN_IN

  const denyRouteChangePassword = route.name === ROUTE_AUTH_PASSWORD_CHANGE && !route.query?.email
  if (denyRouteChangePassword) return ROUTE_AUTH_SIGN_IN
}
