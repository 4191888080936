import {
  ROUTE_DOCUMENTS,
  ROUTE_DOCUMENT_EDIT,
  ROUTE_DOCUMENT_VIEW,
  ROUTE_DOCUMENT_CREATE
} from './constants'

const DocumentView = () => import(/* webpackChunkName: "documents-view" */ './views/DocumentView')
const DocumentForm = () => import(/* webpackChunkName: "documents-form" */ './views/DocumentForm')
const DocumentList = () => import(/* webpackChunkName: "documents-list" */ './views/DocumentList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [{ label: child ? 'Documentos' : 'Buscar Documentos', active: !active, to: { name: ROUTE_DOCUMENTS } }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/documentos',
  name: ROUTE_DOCUMENTS,
  meta: { breadcrumb: configBreadcrumbs() },
  component: DocumentList
}, {
  path: '/documentos/cadastrar',
  name: ROUTE_DOCUMENT_CREATE,
  meta: { breadcrumb: configBreadcrumbs({ label: 'Cadastrar Documento', active: true }) },
  component: DocumentForm
}, {
  path: '/documentos/:id/editar',
  name: ROUTE_DOCUMENT_EDIT,
  meta: { showBackButton: true, breadcrumb: configBreadcrumbs({ label: 'Alterar Documento #', active: true, field: 'description' }) },
  component: DocumentForm
}, {
  path: '/documentos/:id/visualizar',
  name: ROUTE_DOCUMENT_VIEW,
  meta: { showBackButton: true, breadcrumb: configBreadcrumbs({ label: 'Documento #', active: true, field: 'description' }) },
  component: DocumentView
}]
