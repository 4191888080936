import Vue from 'vue'
import Vuex from 'vuex'
import vuexRouterState from './vuexRouterState'
import vuexMiddlewares from './vuexMiddlewares'

import formStore from '@/components/form/store'
import tableStore from '@/components/table/store'

import appStore from '@/modules/app/store'
import authStore from '@/modules/auth/store'

Vue.use(Vuex)

const modules = {
  appStore,
  authStore,
  formStore,
  tableStore
}

export default new Vuex.Store({
  modules,

  plugins: [
    vuexRouterState(),
    vuexMiddlewares(modules)
  ]
})
