import {
  ROUTE_AGENCIES,
  ROUTE_AGENCY_EDIT,
  ROUTE_AGENCY_VIEW,
  ROUTE_AGENCY_CREATE
} from './constants'

const AgencyView = () => import(/* webpackChunkName: "agencies-view" */ './views/AgencyView')
const AgencyForm = () => import(/* webpackChunkName: "agencies-form" */ './views/AgencyForm')
const AgencyList = () => import(/* webpackChunkName: "agencies-list" */ './views/AgencyList')

const configBreadcrumbs = (child, active) => {
  const breadcrumbs = [{ label: child ? 'Orgão Emissor' : 'Buscar Orgão Emissor', active: !active, to: { name: ROUTE_AGENCIES } }]
  if (child) breadcrumbs.push(child)
  return breadcrumbs
}

export default [{
  path: '/orgaos-emissores',
  name: ROUTE_AGENCIES,
  meta: { breadcrumb: configBreadcrumbs() },
  component: AgencyList
}, {
  path: '/orgaos-emissores/cadastrar',
  name: ROUTE_AGENCY_CREATE,
  meta: { breadcrumb: configBreadcrumbs({ label: 'Cadastrar Orgão Emissor', active: true }) },
  component: AgencyForm
}, {
  path: '/orgaos-emissores/:id/editar',
  name: ROUTE_AGENCY_EDIT,
  meta: { showBackButton: true, breadcrumb: configBreadcrumbs({ label: 'Alterar Orgão Emissor #', active: true, field: 'name' }) },
  component: AgencyForm
}, {
  path: '/orgaos-emissores/:id/visualizar',
  name: ROUTE_AGENCY_VIEW,
  meta: { showBackButton: true, breadcrumb: configBreadcrumbs({ label: 'Documento #', active: true, field: 'name' }) },
  component: AgencyView
}]
