import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.yml/i)

  return locales.keys().reduce((messages, key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    return matched && matched.length > 1
      ? { ...messages, [matched[1]]: locales(key) }
      : messages
  }, {})
}

export default new VueI18n({
  locale: 'pt-br',
  fallbackLocale: 'pt-br',
  messages: loadLocaleMessages()
})
