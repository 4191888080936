import router from '@/router'
import Auth from '@/aws/Auth'
import getAuthSession from '@/aws/getAuthSession'
import { ROUTE_FEEDS } from '@/modules/feed/constants'
import log from '@/utils/log'

import {
  ACTION_SIGN_UP,
  ACTION_SIGN_IN,
  ACTION_SIGN_OUT,
  ACTION_AUTH_MFA,
  ACTION_CLEAR_STATE,
  ACTION_AUTH_FORGOT_PASSWORD,
  ACTION_AUTH_CHANGE_PASSWORD,
  ACTION_AUTH_DEFINE_PASSWORD,

  ROUTE_AUTH_SIGN_IN,
  ROUTE_AUTH_SIGN_MFA,
  ROUTE_AUTH_PASSWORD_CHANGE,
  ROUTE_AUTH_PASSWORD_DEFINE
} from './constants'

const AUTH_ERROR = 'AUTH_ERROR'
const AUTH_LOADING = 'AUTH_LOADING'

const SIGN_OUT = 'SIGN_OUT'
const SIGN_MFA = 'SIGN_MFA'
const CLEAR_STATE = 'CLEAR_STATE'
const SET_SESSION = 'SET_SESSION'
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
const PASSWORD_FORGOT_SUCCESS = 'PASSWORD_FORGOT_SUCCESS'
const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS'
const PASSWORD_DEFINE_SUCCESS = 'PASSWORD_DEFINE_SUCCESS'

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'

const isMFA = ({ challengeName }) => ['SMS_MFA', 'EMAIL_MFA'].includes(challengeName)
const forceNewPassword = ({ challengeName }) => challengeName === NEW_PASSWORD_REQUIRED

const resetState = () => ({
  session: null,
  loading: false,

  cognitoUser: null,
  cognitoInfo: null,
  cognitoError: null
})

const toStr = (val) => String(val || '').trim()
const toList = (val) => toStr(val).split(',').filter(v => toStr(v))
const getRoles = (session) => toList(session['custom:cargos']).map(Number)

export default {
  state: resetState(),

  getters: {
    hasCognitoUser: (state) => {
      return Boolean(state.cognitoUser)
    },

    isAuthenticated: (state) => {
      return Boolean(state.session)
    },

    session: (state) => {
      return state.session
    },

    isAdmin: (state) => {
      if (!state.session) return false

      const roles = getRoles(state.session)

      if (hasGeneralRole(roles)) return true
      if (hasRegionRole(roles)) return true
      if (hasLocalRole(roles)) return true

      return false
    },

    isGeneralRole: (state) => {
      return Boolean(state.session) && hasGeneralRole(getRoles(state.session))
    },

    isRegionRole: (state) => {
      return Boolean(state.session) && hasRegionRole(getRoles(state.session))
    },

    isLocalRole: (state) => {
      return Boolean(state.session) && hasLocalRole(getRoles(state.session))
    }
  },

  actions: {
    [ACTION_SIGN_IN]: async ({ dispatch, commit }, { email, password }) => {
      commit(AUTH_LOADING)

      try {
        const cognitoUser = await Auth.signIn(email, password)

        if (isMFA(cognitoUser)) return commit(SIGN_MFA, cognitoUser)
        if (forceNewPassword(cognitoUser)) return commit(NEW_PASSWORD_REQUIRED, cognitoUser)

        const session = await getAuthSession()
        commit(SIGN_IN_SUCCESS, session)
      } catch (err) {
        if (err.name === 'UnexpectedLambdaException') return dispatch(ACTION_SIGN_IN, { email, password })
        log(err)
        commit(AUTH_ERROR, err)
      }
    },

    [ACTION_SIGN_UP]: async ({ commit, dispatch }, { email, nucleoId }) => {
      commit(AUTH_LOADING)

      try {
        await Auth.signUp({
          username: email,
          password: Math.random().toString(36).slice(-8),
          attributes: {
            email,
            name: email,
            'custom:nucleo_codigo': String(nucleoId)
          }
        })

        dispatch(ACTION_AUTH_FORGOT_PASSWORD, email)
      } catch (error) {
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_AUTH_MFA]: async ({ commit, state }, code) => {
      commit(AUTH_LOADING)

      try {
        await Auth.confirmSignIn(state.cognitoUser, code)
        const session = await getAuthSession()
        commit(SIGN_IN_SUCCESS, session)
      } catch (error) {
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_AUTH_FORGOT_PASSWORD]: async ({ commit }, email) => {
      commit(AUTH_LOADING)

      try {
        const cognitoUser = await Auth.forgotPassword(email)
        commit(PASSWORD_FORGOT_SUCCESS, { ...cognitoUser, email })
      } catch (err) {
        log(err)
        commit(AUTH_ERROR, err)
      }
    },

    [ACTION_AUTH_CHANGE_PASSWORD]: async ({ dispatch, commit }, { email, code, password }) => {
      commit(AUTH_LOADING)

      try {
        await Auth.forgotPasswordSubmit(email, code, password)
        dispatch(ACTION_SIGN_IN, { email, password })
      } catch (error) {
        const retryCode = error.message.indexOf('please request a code again') > -1
        if (retryCode) return dispatch(ACTION_AUTH_FORGOT_PASSWORD, email)
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_AUTH_DEFINE_PASSWORD]: async ({ dispatch, commit, state }, password) => {
      commit(AUTH_LOADING)

      try {
        const { email } = state.cognitoUser.challengeParam.userAttributes
        await Auth.completeNewPassword(state.cognitoUser, password)
        dispatch(ACTION_SIGN_IN, { email, password })
      } catch (error) {
        commit(AUTH_ERROR, error)
      }
    },

    [ACTION_SIGN_OUT]: async ({ commit }) => {
      await Auth.signOut().catch(() => ({}))
      commit(SIGN_OUT)
    },

    [ACTION_CLEAR_STATE]: async ({ commit }) => {
      await Auth.signOut().catch(() => ({}))
      commit(CLEAR_STATE)
    }
  },

  mutations: {
    [AUTH_LOADING]: (state) => {
      Object.assign(state, {
        loading: true,
        cognitoInfo: null,
        cognitoError: null
      })
    },

    [SIGN_IN_SUCCESS]: (state, session) => {
      Object.assign(state, {
        session,
        loading: false,
        cognitoUser: null
      })
    },

    [SET_SESSION]: (state, session) => {
      Object.assign(state, {
        session,
        loading: false,
        cognitoUser: null
      })
    },

    [PASSWORD_FORGOT_SUCCESS]: (state, cognitoUser) => {
      Object.assign(state, {
        loading: false,
        cognitoInfo: PASSWORD_FORGOT_SUCCESS,
        cognitoUser
      })
    },

    [SIGN_MFA]: (state, cognitoUser) => {
      Object.assign(state, {
        loading: false,
        cognitoUser,
        cognitoInfo: SIGN_MFA
      })
    },

    [NEW_PASSWORD_REQUIRED]: (state, cognitoUser) => {
      Object.assign(state, {
        loading: false,
        cognitoUser,
        cognitoInfo: NEW_PASSWORD_REQUIRED
      })
    },

    [PASSWORD_DEFINE_SUCCESS]: (state) => {
      Object.assign(state, {
        loading: false,
        cognitoUser: null,
        cognitoInfo: PASSWORD_DEFINE_SUCCESS
      })
    },

    [AUTH_ERROR]: (state, error) => {
      Object.assign(state, {
        loading: false,
        cognitoError: error.message
      })
    },

    [PASSWORD_CHANGE_SUCCESS]: (state) => {
      Object.assign(state, {
        loading: false,
        cognitoUser: null,
        cognitoInfo: PASSWORD_DEFINE_SUCCESS
      })
    },

    [SIGN_OUT]: (state) => {
      Object.assign(state, resetState())
    },

    [CLEAR_STATE]: (state) => {
      Object.assign(state, resetState())
    }
  },

  middlewares: {
    [SIGN_OUT]: () => router.push({ name: ROUTE_AUTH_SIGN_IN }).catch(() => ({})),
    [SIGN_MFA]: () => router.push({ name: ROUTE_AUTH_SIGN_MFA }).catch(() => ({})),
    [SIGN_IN_SUCCESS]: () => router.push({ name: ROUTE_FEEDS }).catch(() => ({})),
    [NEW_PASSWORD_REQUIRED]: () => router.push({ name: ROUTE_AUTH_PASSWORD_DEFINE }).catch(() => ({})),
    [PASSWORD_FORGOT_SUCCESS]: (_, { payload: { email } }) => router.push({ name: ROUTE_AUTH_PASSWORD_CHANGE, query: { email } }).catch(() => ({})),
    [PASSWORD_DEFINE_SUCCESS]: (_, { payload: email }) => router.push({ name: ROUTE_AUTH_SIGN_IN, query: { email } }).catch(() => ({})),

    onRouteChanged: (ctx, { payload: routeName }) => routeName === ROUTE_AUTH_SIGN_IN && ctx.commit(CLEAR_STATE)
  }
}

const secretariaGeralDG = 5
const secretariaAdjuntaDG = 6
const secretariaAdjuntaDGInternalcional = 333
const mestreGeralRepresentante = 1
const mestreAssistenteGeral = 2
const secretariaCentral = 38
const mestreCentral = 35
const mestreAssistenteCentral = 36
const primeiraSecretariaLocal = 51
const segundaSecretariaLocal = 52
const mestreRepresentante = 47
const presidenteNucleo = 49
const vicePresidenteOperacional = 336
const presidenteProperar = 339

export const hasLocalRole = (roles) => Boolean([primeiraSecretariaLocal, segundaSecretariaLocal, mestreRepresentante, presidenteNucleo].find(role => roles.includes(role)))
export const hasRegionRole = (roles) => Boolean([secretariaCentral, mestreCentral, mestreAssistenteCentral].find(role => roles.includes(role)))
export const hasGeneralRole = (roles) => Boolean([secretariaGeralDG, secretariaAdjuntaDG, secretariaAdjuntaDGInternalcional, mestreGeralRepresentante, mestreAssistenteGeral, vicePresidenteOperacional, presidenteProperar].find(role => roles.includes(role)))
