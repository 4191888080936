import api from '@/aws/api'

const FORM_CLEAR = 'FORM_CLEAR'

const FORM_LOADED = 'FORM_LOADED'
const FORM_LOADING = 'FORM_LOADING'
const FORM_LOAD_ERROR = 'FORM_LOAD_ERROR'

const FORM_SAVED = 'FORM_SAVED'
const FORM_SAVING = 'FORM_SAVING'
const FORM_SAVE_ERROR = 'FORM_SAVE_ERROR'

const initialState = () => ({
  data: null,
  error: null,

  isSaved: false,
  isSaving: false,
  errorSave: null,

  isLoaded: false,
  isLoading: false,
  errorLoad: null,

  canRetry: false
})

export default {
  namespaced: true,
  state: initialState(),

  actions: {
    async getApi ({ commit }, getUrl) {
      commit(FORM_LOADING)

      try {
        const result = getUrl ? await api.get(getUrl) : {}
        const data = result || {}
        commit(FORM_LOADED, data)
      } catch (err) {
        commit(FORM_LOAD_ERROR, { getUrl, err })
      }
    },

    async sendApi ({ commit }, payload) {
      commit(FORM_SAVING)

      try {
        const { saveUrl, isEdit } = payload
        const body = payload.body ? clearBody(payload.body) : {}
        const result = await api[isEdit ? 'put' : 'post'](saveUrl, body)
        const data = result || {}
        commit(FORM_SAVED, data)
      } catch (err) {
        commit(FORM_SAVE_ERROR, { ...payload, err: getError(err) })
      }
    },

    clear ({ commit }) {
      commit(FORM_CLEAR)
    }
  },

  mutations: {
    [FORM_LOADING] (state) {
      Object.assign(state, {
        isLoaded: false,
        isLoading: true,
        canRetry: true
      })
    },

    [FORM_LOADED] (state, data) {
      Object.assign(state, {
        data,
        isLoaded: true,
        isLoading: false
      })
    },

    [FORM_LOAD_ERROR] (state, { err }) {
      Object.assign(state, {
        errorLoad: 'O sistema está tentando carregar as informações.',
        isLoading: false
      })
    },

    [FORM_SAVING] (state) {
      Object.assign(state, {
        isSaved: false,
        isSaving: true,
        canRetry: false
      })
    },

    [FORM_SAVED] (state, data) {
      Object.assign(state, {
        data,
        isSaved: true,
        isSaving: false
      })
    },

    [FORM_SAVE_ERROR] (state, { err }) {
      Object.assign(state, {
        errorSave: err,
        isSaving: false
      })
    },

    [FORM_CLEAR] (state) {
      Object.assign(state, initialState())
    }
  },

  middlewares: {
    'formStore/FORM_LOAD_ERROR': ({ dispatch }, { payload }) => (
      setTimeout(() => (
        dispatch('formStore/getApi', payload.getUrl)
      ), 1500)
    )
  }
}

const getError = ({ response }) => {
  if (response.status === 400) return response
  return { $errors: { form: `Aconteceu um erro interno na nuvem: #${response.status}` } }
}

const clearBody = (body) => (
  Object.keys(body).reduce((acc, key) => {
    if (!key || key[0] === '$') return acc
    if ([null, undefined].includes(body[key])) return acc
    return { ...acc, [key]: body[key] }
  }, {})
)
