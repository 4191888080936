import Auth from './Auth'

export default () => (
  getIdToken().then((idToken) => (
    idToken?.payload
  ))
)

export const getTokenJWT = () => (
  getIdToken().then((idToken) => (
    idToken?.jwtToken
  ))
)

export const getIdToken = () => (
  Auth.currentSession()
    .then((session) => session?.idToken)
    .catch(() => null)
)
