import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from './modules/auth/routes'
import feedRoutes from './modules/feed/routes'
import dashboardRoutes from './modules/dashboard/routes'
import schedulesRoutes from './modules/schedules/routes'
import documentsRoutes from './modules/documents/routes'
import agenciesRoutes from './modules/agencies/routes'
import { ROUTE_FEEDS } from './modules/feed/constants'

Vue.use(VueRouter)

const panelRoutes = {
  path: '/',
  component: () => import(/* webpackChunkName: "main-layout" */ './modules/app/views/MainLayout'),
  redirect: { name: ROUTE_FEEDS },
  children: [
    ...feedRoutes,
    ...schedulesRoutes,
    ...dashboardRoutes,
    ...documentsRoutes,
    ...agenciesRoutes
  ]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    authRoutes,
    panelRoutes
  ]
})

export default router
