import Auth from '@aws-amplify/auth'

const USER_POOL_ID = 'us-east-1_QCJtSIWeq'
const USER_POOL_REGION = 'us-east-1'
const USER_POOL_WEB_CLIENT_ID = '3dbufiocfv5h1tbsjpttqr7lhr'
const AUTHENTICATION_FLOW_TYPE = 'USER_SRP_AUTH'

Auth.configure({
  region: USER_POOL_REGION,
  userPoolId: USER_POOL_ID,
  userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: AUTHENTICATION_FLOW_TYPE
})

export default Auth
