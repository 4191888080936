import api from '@/aws/api'
import log from '@/utils/log'

const SET_QUERY = 'SET_QUERY'
const OPEN_FILTERS = 'OPEN_FILTERS'
const CLOSE_FILTERS = 'CLOSE_FILTERS'
const REQUEST_ERROR = 'REQUEST_ERROR'
const REQUEST_CLEAR = 'REQUEST_CLEAR'
const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
const REQUEST_LOADING = 'REQUEST_LOADING'

const resetState = () => ({
  rows: [],
  count: 0,
  query: {},

  filters: [],
  openFilters: false,

  error: null,
  loaded: false,
  hasMore: false,
  loading: false,
  canRetry: false
})

const actions = {
  async requestList ({ commit, state }, endpoint) {
    commit(REQUEST_LOADING)

    try {
      const query = { ...state.query }
      const result = await api.get(endpoint, query)
      commit(REQUEST_SUCCESS, result)
    } catch (err) {
      commit(REQUEST_ERROR, { endpoint, err })
    }
  },

  setQuery ({ commit }, query) {
    commit(SET_QUERY, query)
  },

  clear ({ commit }) {
    commit(REQUEST_CLEAR)
  },

  toggleFilters ({ commit, state }, filters) {
    const mutation = state.openFilters ? CLOSE_FILTERS : OPEN_FILTERS
    commit(mutation, filters)
  }
}

const mutations = {
  [SET_QUERY]: (state, query) => (
    state.query = query ? { ...query } : {}
  ),

  [OPEN_FILTERS]: (state, filters) => (
    Object.assign(state, {
      filters: [...filters],
      openFilters: true
    })
  ),

  [CLOSE_FILTERS]: (state) => (
    Object.assign(state, {
      openFilters: false
    })
  ),

  [REQUEST_LOADING]: (state) => (
    Object.assign(state, {
      loading: true,
      canRetry: true
    })
  ),

  [REQUEST_SUCCESS]: (state, result) => {
    const rows = state.query.page === 1 ? result.rows : reduceRows(state.rows, result.rows)
    const count = result.count

    Object.assign(state, {
      rows,
      count,
      error: null,
      loaded: true,
      loading: false,
      hasMore: rows.length < count
    })
  },

  [REQUEST_ERROR]: (state, { err }) => {
    log(err)

    Object.assign(state, {
      error: 'O sistema está tentando carregar as informações.',
      loading: false
    })
  },

  [REQUEST_CLEAR]: (state) => (
    Object.assign(state, resetState())
  )
}

const reduceRows = (stateRows, resultRows) => {
  const stateIds = stateRows.reduce((grp, row) => grp.concat(row.id), [])
  return [...stateRows, ...resultRows.filter(({ id }) => !stateIds.includes(id))]
}

const middlewares = {
  'tableStore/REQUEST_ERROR': ({ dispatch }, { payload }, { canRetry }) => {
    if (!canRetry) return
    setTimeout(() => {
      if (!canRetry) return
      dispatch('tableStore/requestList', payload)
    }, 1500)
  }
}

export default {
  actions,
  mutations,
  middlewares,
  state: resetState(),
  namespaced: true
}
