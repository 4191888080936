import {
  ROUTE_DASHBOARD,
  REPORT_REGISTERS,
  REPORT_ACCESS,
  REPORT_DOCUMENTS,
  REPORT_AFFILIATES
} from './constants'

const DashboardPage = () => import(/* webpackChunkName: "dashboard-list" */ './views/DashboardPage')

export default [{
  path: '/relatorios',
  name: ROUTE_DASHBOARD,
  redirect: '/relatorios/socios/cadastrados'
}, {
  path: '/relatorios/socios/cadastrados',
  name: REPORT_REGISTERS,
  component: DashboardPage
}, {
  path: '/relatorios/socios/acessos',
  name: REPORT_ACCESS,
  component: DashboardPage
}, {
  path: '/relatorios/documentos/acessos',
  name: REPORT_DOCUMENTS,
  component: DashboardPage
}, {
  path: '/relatorios/socios/consulta',
  name: REPORT_AFFILIATES,
  component: DashboardPage
}]
