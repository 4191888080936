import {
  ROUTE_FEED,
  ROUTE_FEEDS
} from './constants'

const FeedList = () => import(/* webpackChunkName: "feed-list" */ './views/FeedList')
const FeedView = () => import(/* webpackChunkName: "feed-view" */ './views/FeedView')

export default [{
  path: '/feeds',
  name: ROUTE_FEEDS,
  component: FeedList,
  children: [{
    path: ':docType/:id',
    name: ROUTE_FEED,
    component: FeedView
  }]
}]
