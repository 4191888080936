export const ACTION_SIGN_IN = 'ACTION_SIGN_IN'
export const ACTION_SIGN_UP = 'ACTION_SIGN_UP'
export const ACTION_SIGN_OUT = 'ACTION_SIGN_OUT'
export const ACTION_AUTH_MFA = 'ACTION_AUTH_MFA'
export const ACTION_CLEAR_STATE = 'ACTION_CLEAR_STATE'
export const ACTION_AUTH_FORGOT_PASSWORD = 'ACTION_AUTH_FORGOT_PASSWORD'
export const ACTION_AUTH_CHANGE_PASSWORD = 'ACTION_AUTH_CHANGE_PASSWORD'
export const ACTION_AUTH_DEFINE_PASSWORD = 'ACTION_AUTH_DEFINE_PASSWORD'

export const ROUTE_AUTH_SIGN_IN = 'ROUTE_AUTH_SIGN_IN'
export const ROUTE_AUTH_SIGN_UP = 'ROUTE_AUTH_SIGN_UP'
export const ROUTE_AUTH_SIGN_MFA = 'ROUTE_AUTH_SIGN_MFA'
export const ROUTE_AUTH_PASSWORD_FORGOT = 'ROUTE_AUTH_PASSWORD_FORGOT'
export const ROUTE_AUTH_PASSWORD_DEFINE = 'ROUTE_AUTH_PASSWORD_DEFINE'
export const ROUTE_AUTH_PASSWORD_CHANGE = 'ROUTE_AUTH_PASSWORD_CHANGE'
