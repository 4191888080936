import Vue from 'vue'
import i18n from './i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import getAuthSession from '@/aws/getAuthSession'
import { ObserveVisibility } from 'vue-observe-visibility'
import './quasar'

const userAgent = navigator.userAgent || navigator.vendor || window.opera
const isIOS = /iPad|iPhone|iPod/i.test(userAgent)
const isAndroid = /android/i.test(userAgent)

if (isIOS) window.location.href = 'https://apps.apple.com/br/app/mural-digital-udv/id1572556922'
if (isAndroid) window.location.href = 'https://play.google.com/store/apps/details?id=br.org.udv.muraldigital'

if (!isIOS && !isAndroid) {
  Vue.use(Vuelidate)
  Vue.directive('observe-visibility', ObserveVisibility)

  Vue.config.productionTip = false
  document.title = 'Mural Digital'

  getAuthSession().then((session) => {
    if (session) store.commit('SET_SESSION', session)

    new Vue({
      i18n,
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  })
}
