import { API } from '@aws-amplify/api'
import { getTokenJWT } from '@/aws/getAuthSession'
// import log from '@/utils/log'

const apiMural = 'mural'
const API_HOST = 'https://api.muraldigital.udv.org.br/v1'
// const API_HOST = 'http://0.0.0.0:3000/sandbox'

API.configure({
  endpoints: [{
    name: apiMural,
    endpoint: API_HOST,
    custom_header: () => (
      getTokenJWT().then((token) => (
        token ? { Authorization: `Bearer ${token}` } : {}
      ))
    )
  }]
})

const cache = {}
const _get = (endpoint) => (queryStringParameters) => API.get(apiMural, endpoint, { queryStringParameters })
const _put = (endpoint) => (body) => API.put(apiMural, endpoint, { body })
const _post = (endpoint) => (body) => API.post(apiMural, endpoint, { body })
const _delete = (endpoint) => () => API.del(apiMural, endpoint)

const _cached = (endpoint) => async (queryStringParameters) => {
  if (!cache[endpoint]) cache[endpoint] = {}

  const params = queryStringParameters ? JSON.stringify(queryStringParameters) : ''

  if (!cache[endpoint][params]) {
    const values = await _get(endpoint)(queryStringParameters)
    cache[endpoint][params] = JSON.stringify(values)
  }

  return JSON.parse(cache[endpoint][params])
}

export default {
  get: (endpoint, queryStringParameters) => _get(endpoint)(queryStringParameters),
  put: (endpoint, body) => _put(endpoint)(body),
  post: (endpoint, body) => _post(endpoint)(body),
  delete: (endpoint) => _delete(endpoint)(),

  getFeeds: _get('/feeds'),
  getKeywords: _get('/keywords'),
  getSchedules: _get('/schedules'),
  getSignedPostUrl: _post('/attachments'),

  getCores: _cached('/cores'),
  getRoles: _cached('/roles'),
  getDegrees: _cached('/degrees'),
  getBoardCouncil: _cached('/board-council'),
  getRegions: _cached('/regions'),
  getAgencies: _cached('/agencies'),
  getDocumentTypes: _cached('/document-types'),
  getDocumentSubTypes: _cached('/document-sub-types'),

  getDashAccess: (filters) => _get('/dashboards/access')(filters),
  getDashRegisters: (filters) => _get('/dashboards/registers')(filters),
  getDashDocuments: (filters) => _get('/dashboards/documents')(filters),
  getDashAffiliates: (filters) => _get('/dashboards/affiliates')(filters),

  getFeed: (id) => _get(`/feeds/${id}`)(),
  getDocument: (id) => _get(`/admin/documents/${id}`)(),
  getDocuments: (filters) => _get('/admin/documents')(filters),
  getAgency: (id) => _get(`/agencies/${id}`)(),
  getAttachS3Link: (id) => getTokenJWT().then((token) => _get(`/attachments/${id}/link-s3`)({ token })),

  cancel: (promise) => API.cancel(promise, 'UNKNOW')
}

export const attachView = ({ id, url }) => (
  url
    ? Promise.resolve(url)
    : getTokenJWT().then((token) => urlImageView(id, token))
)

export const urlImageView = (id, token, mode = 'view') => (
  `${API_HOST}/attachments/${id}?token=${token}&mode=${mode}`
)

export const getApi = (endpoint, queryStringParameters) => (
  API.get(apiMural, endpoint, { queryStringParameters })
)
